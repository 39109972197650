/** @jsxImportSource react */
import { cart } from '$/store/cart'
import { $userDiscounts } from '@store/user-discounts'
import { useStore } from '@nanostores/react'
import type A11yDialogInstance from 'a11y-dialog'
import clsx from 'clsx'
import { PropsWithChildren, ReactNode, useEffect, useRef, useState } from 'react'
import SiteModal from './SiteModal'
import { Badge } from './Badge/Badge'
import { ReactComponent as CartIcon } from '$/icons/redesign/cart.svg'

interface Props {
  buttonProps?: {
    className?: string
    'aria-label'?: string
    'data-grid'?: string
  }
  footer?: ReactNode
}

function MiniCartHeader({ cartCount }: { cartCount: number }) {
  const items = cartCount !== 1 ? 'items' : 'item'
  const { buy3DiscountPercentage } = useStore($userDiscounts)

  return (
    <div className="repel u-ml-2 u-flex-1">
      <span aria-hidden="true" className="u-font-semibold">
        Your Bag
      </span>
      <div className="u-flex u-items-center">
        <span>
          {cartCount} {items}
        </span>
        {cartCount >= 3 && <Badge className="u-ml-3">Extra {buy3DiscountPercentage}% savings</Badge>}
      </div>
    </div>
  )
}

function MiniCartFooter({ cartCount }: { cartCount: number }) {
  return cartCount > 0 ? (
    <a href="/shop-first" className="btn btn--block">
      Go To Checkout
    </a>
  ) : (
    <button aria-disabled="true" className="btn btn--block">
      Go To Checkout
    </button>
  )
}

export default function HeaderCartModal(props: PropsWithChildren<Props>) {
  const dialog = useRef<A11yDialogInstance>()
  const { cartItems } = useStore(cart)
  const cartCount = cartItems.reduce((acc, item) => acc + item.quantity, 0)
  const [hasItems, setHasItems] = useState(false)

  const openModal = () => dialog.current?.show()

  useEffect(() => {
    window.addEventListener('ui:cart', openModal)
    return () => window.removeEventListener('ui:cart', openModal)
  }, [])

  // dumb way of syncing classes without hydration errors
  useEffect(() => setHasItems(!!cartCount), [cartCount])

  return (
    <>
      <button
        aria-label="Open bag"
        className={clsx(
          'header_cart-button u-grid u-h-[32px] u-w-[32px] u-place-content-center',
          hasItems && 'has-items',
        )}
        data-grid="cart"
        onClick={openModal}
      >
        <CartIcon className="nav-icon" />
      </button>

      <SiteModal
        id="bag"
        dialogRef={(d) => (dialog.current = d)}
        closeButtonLabel="Close bag"
        title="Your Bag"
        headerContent={<MiniCartHeader cartCount={cartCount} />}
        type="drawer"
        classNames={{
          container: 'site-dialog-container',
          overlay: 'site-dialog-overlay',
          dialog: 'site-dialog-content full-screen',
          header:
            'u-mt-4 u-flex u-items-center u-flex-row-reverse u-justify-between u-border-b u-pb-4 u-pl-[15px] u-pr-4',
          title: 'action u-py-4 u-border-b u-flex u-items-center u-pl-[15px]',
          closeButton: 'u-h-6 u-w-6',
        }}
      >
        <div className="u-flex u-flex-1 u-flex-col u-overflow-x-hidden u-overflow-y-scroll u-px-[15px] u-py-4 u-pb-6 desktop:u-px-4">
          {props.children}
        </div>
        {!!cartCount && (
          <div className="site-dialog-footer">
            <MiniCartFooter cartCount={cartCount} />
          </div>
        )}
      </SiteModal>
    </>
  )
}
